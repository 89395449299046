/*
 * @Author: kok-s0s
 * @Date: 2021-08-17 17:16:55
 * @LastEditors: kok-s0s
 * @LastEditTime: 2021-08-17 17:28:44
 * @Description: file content
 */
import React from "react";
import ReactDOM from "react-dom";
import Calculator from "./App";
import "./App.css";

ReactDOM.render(
	<React.StrictMode>
		<Calculator />
	</React.StrictMode>,
	document.getElementById("root")
);
